import React from 'react';
import { Box, Button, Grid } from '@mui/material';

export const ScheduleADemo = () => {
  return (
    <Grid container sx={{ height: '100vh' }}>
      <Grid item xs={12} md={6}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: '100%',
            height: '60vh',
            background: '#EAF2FF',
            paddingBottom: '20px',
          }}
        ></Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={10} lg={8}>
            <Box>
              <h1>Welcome to Double Agent!</h1>
              <p>
                See how you can obtain a sample quote for your client in just a
                few easy steps!
              </p>
              <Button variant="contained" fullWidth>
                Schedule a Demo
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const BecomeAnLO = () => {
  return (
    <Grid container sx={{ height: '100vh' }}>
      <Grid item xs={12} md={6}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: '100%',
            height: '60vh',
            background: '#EAF2FF',
            paddingBottom: '20px',
          }}
        ></Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={10} lg={8}>
            <Box>
              <h1>Become a Loan Originator</h1>
              <p>
                See how you can get Licensed quickly as a Loan Originator with
                the Double Agent discount!
              </p>
              <Button variant="contained" fullWidth>
                Get Licensed
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
