import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Page } from '../../UI/PageLayout/Page';
import { useApplications } from './useApplications';
import { Alert, Box, Button, Modal, Snackbar } from '@mui/material';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import { ApplicationForm } from './applicationForm';
import { EmptyState } from '../Quotes/quotes';

interface ApplicationProps {}
interface RateQuote {
  id: string;
  creditScore: string;
  zipCode: number;
  loanPurpose: string;
  purchasePrice: number;
  LTV: number;
  firstTimeHomebuyer: boolean;
  isMilitary: boolean;
  lockPeriod: number;
  loanTerm: number;
  occupancyType: string;
  propertyType: string;
  numUnits: number;
  compensationType: string;
  name: string;
  email: string;
  phone: string;
  rateQuote: string;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  outline: 0,
  maxHeight: '80vh',
  overflow: 'auto',
};

export const Applications: React.FC<ApplicationProps> = () => {
  const { data: applications, isLoading } = useApplications();
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseSnackbar = () => {
    setIsOpen(false);
  };

  const toggleModal = (isOpen) => {
    setIsModalOpen(isOpen);
  };

  return (
    <Page.Wrapper>
      <Page.Header>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Page.Title>Applications</Page.Title>
          <Button
            onClick={() => toggleModal(true)}
            variant="contained"
            size="large"
          >
            <AddIcon />
          </Button>
        </div>
      </Page.Header>
      <Page.Content>
        {applications?.length > 0 && (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Application Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {applications?.map((application) => (
                  <TableRow key={application.id}>
                    <TableCell>
                      <div>
                        {application.clientName || '[Client Name not provided]'}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {(!applications || applications.length < 1 || isLoading) && (
          <EmptyState
            header="Nothing here, yet."
            buttonText="New Application"
            subHeader="This is where you will find your applications in progress!"
            isLoading={isLoading}
            onClick={() => toggleModal(true)}
          />
        )}
      </Page.Content>
      <Snackbar
        open={isOpen}
        onClose={handleCloseSnackbar}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="info">Copied Url to clipboard</Alert>
      </Snackbar>
      <Modal
        open={isModalOpen}
        onClose={() => toggleModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ApplicationForm onSubmit={() => toggleModal(false)} />
        </Box>
      </Modal>
    </Page.Wrapper>
  );
};
