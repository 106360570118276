import React, { useState } from 'react';
import { Page } from '../../UI/PageLayout/Page';
import { useQuotes } from './useQuotes';
import AddIcon from '@mui/icons-material/Add';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  Snackbar,
} from '@mui/material';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import { RateQuoteForm } from './rateQuoteForm';

interface QuoteProps {}
interface RateQuote {
  id: string;
  creditScore: string;
  zipCode: number;
  loanPurpose: string;
  purchasePrice: number;
  LTV: number;
  firstTimeHomebuyer: boolean;
  isMilitary: boolean;
  lockPeriod: number;
  loanTerm: number;
  occupancyType: string;
  propertyType: string;
  numUnits: number;
  compensationType: string;
  name: string;
  email: string;
  phone: string;
  rateQuote: string;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  outline: 0,
  maxHeight: '80vh',
  overflow: 'auto',
};

export const EmptyState: React.FC<{
  onClick: () => void;
  header?: string;
  subHeader?: string;
  buttonText?: string;
  isLoading?: boolean;
}> = (props) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: '60vh' }}
    >
      {!props.isLoading && (
        <Grid item xs={6}>
          {props.header && <h3>{props.header}</h3>}
          {props.subHeader && <p>{props.subHeader}</p>}
          <Button
            onClick={() => props.onClick()}
            variant="contained"
            size="large"
          >
            {props.buttonText}
          </Button>
        </Grid>
      )}
      {props.isLoading && <CircularProgress />}
    </Grid>
  );
};

export const Quotes: React.FC<QuoteProps> = () => {
  const { data: quotes, isLoading } = useQuotes();
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseSnackbar = () => {
    setIsOpen(false);
  };

  const toggleModal = (isOpen) => {
    setIsModalOpen(isOpen);
  };

  return (
    <Page.Wrapper>
      <Page.Header>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Page.Title>Quotes</Page.Title>
          <Button
            onClick={() => toggleModal(true)}
            variant="contained"
            size="large"
          >
            <AddIcon />
          </Button>
        </div>
      </Page.Header>
      <Page.Content>
        {quotes?.length > 0 && (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>QUOTE NAME</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {quotes?.map((quote) => (
                  <TableRow key={quote.id}>
                    <TableCell>
                      <div>
                        {quote.clientName || '[Client Name not provided]'}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {(!quotes || quotes.length < 1 || isLoading) && (
          <EmptyState
            header="Nothing here, yet."
            subHeader="This is where you'll find your quotes in progress!"
            buttonText="New Quote"
            isLoading={isLoading}
            onClick={() => {
              toggleModal(true);
            }}
          />
        )}
      </Page.Content>
      <Snackbar
        open={isOpen}
        onClose={handleCloseSnackbar}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="info">Copied Url to clipboard</Alert>
      </Snackbar>
      <Modal
        open={isModalOpen}
        onClose={() => toggleModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <RateQuoteForm onSubmit={() => toggleModal(false)} />
        </Box>
      </Modal>
    </Page.Wrapper>
  );
};
