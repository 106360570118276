import React from 'react';
import { Form } from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { useAuth } from './AuthProvider';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { UserCredential } from 'firebase/auth';

const schema: RJSFSchema = {
  type: 'object',
  required: ['email', 'password', 'confirmPassword'],
  properties: {
    email: { type: 'string', title: 'Email', format: 'email' },
    password: { type: 'string', title: 'Password', format: 'password' },
    confirmPassword: {
      type: 'string',
      title: 'Confirm Password',
      format: 'password',
    },
    agreeToPolicy: {
      type: 'boolean',
      title:
        "I've read and agree with the Terms and Conditions and the Privacy Policy",
    },
  },
};

export const SignupPage = (props: {
  onSignup: (user: UserCredential | null) => void;
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const auth = useAuth();

  const signup = async (creds) => {
    try {
      setIsLoading(true);
      const user = await auth.signUp(creds, true);
      // setFirebaseError('');
      setIsLoading(false);

      props.onSignup(user);
    } catch (e: any) {
      setIsLoading(false);

      if (e.code == 'auth/invalid-email') {
        // setFirebaseError('Invalid Email');
      } else if (e.code === 'auth/weak-password') {
        // setFirebaseError('Weak password. Please enter a stronger password.');
      } else if (e.code == 'auth/email-already-in-use') {
        // setFirebaseError('Email already in use. Forgot password?');
      } else {
        // setFirebaseError(`Unknown error occurred: ${e.code}`);
      }
    }
  };
  const handleSignupSubmit = (form) => {
    signup(form.formData);
  };
  return (
    <Box>
      <h1>Sign up</h1>
      <p>Create an account to get started</p>
      <Form schema={schema} validator={validator} onSubmit={handleSignupSubmit}>
        <Grid>
          <Grid item>
            <Button
              type="submit"
              disabled={isLoading}
              variant="contained"
              color="primary"
              size="large"
              fullWidth
            >
              Sign Up
              {isLoading && <CircularProgress color="success" size={14} />}
            </Button>
          </Grid>
        </Grid>
      </Form>
      <p>
        Already a Double Agent? <Link to="/login">Login Now!</Link>
      </p>
    </Box>
  );
};
