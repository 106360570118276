import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Page } from '../../UI/PageLayout/Page';
import { useContacts } from './useContacts';
import { Alert, Box, Button, Modal, Snackbar } from '@mui/material';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import { NewContactForm } from './contactForms';
import { EmptyState } from '../Quotes/quotes';

interface ContactProps {}
interface RateQuote {
  id: string;
  creditScore: string;
  zipCode: number;
  loanPurpose: string;
  purchasePrice: number;
  LTV: number;
  firstTimeHomebuyer: boolean;
  isMilitary: boolean;
  lockPeriod: number;
  loanTerm: number;
  occupancyType: string;
  propertyType: string;
  numUnits: number;
  compensationType: string;
  name: string;
  email: string;
  phone: string;
  rateQuote: string;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  outline: 0,
  maxHeight: '80vh',
  overflow: 'auto',
};

export const Contacts: React.FC<ContactProps> = () => {
  const { data: contacts, isLoading } = useContacts();
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseSnackbar = () => {
    setIsOpen(false);
  };

  const toggleModal = (isOpen) => {
    setIsModalOpen(isOpen);
  };

  return (
    <Page.Wrapper>
      <Page.Header>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Page.Title>Contacts</Page.Title>
          <Button
            onClick={() => toggleModal(true)}
            variant="contained"
            size="large"
          >
            <AddIcon />
          </Button>
        </div>
      </Page.Header>
      <Page.Content>
        {contacts?.length > 0 && (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Contact Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contacts?.map((contact) => (
                  <TableRow key={contact.id}>
                    <TableCell>
                      <div>
                        {`${contact.firstName} ${contact.lastName}` ||
                          '[contact Name not provided]'}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {(!contacts || contacts.length < 1 || isLoading) && (
          <EmptyState
            header="Nothing here, yet."
            buttonText="New Contact"
            subHeader="This is where you will find your Contacts!"
            isLoading={isLoading}
            onClick={() => toggleModal(true)}
          />
        )}
      </Page.Content>
      <Snackbar
        open={isOpen}
        onClose={handleCloseSnackbar}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="info">Copied Url to clipboard</Alert>
      </Snackbar>
      <Modal
        open={isModalOpen}
        onClose={() => toggleModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <NewContactForm onSubmit={() => toggleModal(false)} />
        </Box>
      </Modal>
    </Page.Wrapper>
  );
};
