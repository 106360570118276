import React from 'react';
import { Button, Grid } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { WelcomePage } from './loginPage';

interface IProps {}

const NewToImage = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '100%',
        height: '100%',
        background: '#EAF2FF',
        paddingBottom: '20px',
      }}
    >
      <Grid item xs={8} alignContent="center">
        <h2>New to Double Agent?</h2>
        <p>Let us show you how easy it is to become a Loan Originator</p>
        <Button variant="outlined" fullWidth href="/schedule-demo">
          See How it Works
        </Button>
      </Grid>
    </Grid>
  );
};
export const Login: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  // @ts-ignore
  const from = location.state?.from?.pathname || '/client/quotes';

  return (
    <Grid container sx={{ height: '100vh' }}>
      <Grid item xs={12} md={6}>
        <NewToImage />
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={10} lg={8}>
            <WelcomePage
              onSignin={() => {
                navigate(from, { replace: true });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
