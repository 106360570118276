import { useQuery } from 'react-query';
import { collection, addDoc, query, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { HOSTNAME } from '../../index';
import { useAuth } from '../Login/AuthProvider';

export type Product = {
  id: string;
  name: string;
  type: 'mortgageLoanCalculator' | 'carLoanCalculator';
  url: string;
  shareable_url: string;
};
export type TransactionInfo = {
  productId: string;
  userId: string;
  userEmail: string;
  transactionStatus: 'pending' | 'trial' | 'succeeded';
  stripePaymentIntentId: string;
  stripeCustomerId: string;
  referrerUserId: string;
};

export const useQuotes = () => {
  return useQuery('quotes', async () => {
    const res = await fetch(`${HOSTNAME}/api/quotes`);
    const { data } = await res.json();
    return data;
  });
};

export const useAddQuote = () => {
  const firestore = useFirestore();
  return async (quoteInfo: {
    rate: string;
    clientName: string;
    userId: string;
  }) => {
    try {
      const docRef = await addDoc(collection(firestore, 'quotes'), quoteInfo);
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };
};

export const useQuote = (id: string) => {
  return useQuery(['quotes', id], async () => {
    const res = await fetch(`${HOSTNAME}/api/quotes/${id}`);
    const { data } = await res.json();
    return data;
    // return Promise.resolve(TEMP_PRODUCTS.find((prod) => prod.id === id));
  });
};

export const useTransactionHistory = (id?: string) => {
  const firestore = useFirestore();
  const transactionCollection = collection(firestore, 'transactions');
  const transactionsQuery = query(
    transactionCollection,
    where('referrerUserId', '==', id)
  );
  return useFirestoreCollectionData(transactionsQuery, {
    idField: 'id', // this field will be added to the object created from each document
  });
};

export const useUserProductAccess = ({ productId, referrerUserId }) => {
  const { user } = useAuth();
  const firestore = useFirestore();
  const transactionsCollection = collection(firestore, 'transactions');
  const transactionsQuery = query(
    transactionsCollection,
    where('userId', '==', user?.uid || ''),
    where('productId', '==', productId),
    where('referrerUserId', '==', referrerUserId)
  );
  return useFirestoreCollectionData(transactionsQuery, {
    idField: 'id', // this field will be added to the object created from each document
  });
};

export const usePurchaseProduct = () => {
  const firestore = useFirestore();
  return async (transactionInfo: TransactionInfo) => {
    try {
      const date = new Date();
      const docRef = await addDoc(collection(firestore, 'transactions'), {
        ...transactionInfo,
        purchaseTimestamp: date.getTime(),
        purchaseDate: date.toISOString(),
      });
      return docRef;
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };
};
