import { useQuery } from 'react-query';
import { collection, addDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { HOSTNAME } from '../../index';

export const useApplications = () => {
  return useQuery('applications', async () => {
    const res = await fetch(`${HOSTNAME}/api/applications`);
    const { data } = await res.json();
    return data;
  });
};

export const useAddApplication = () => {
  const firestore = useFirestore();
  return async (applicationInfo: {
    rate: string;
    clientName: string;
    userId: string;
  }) => {
    try {
      const docRef = await addDoc(
        collection(firestore, 'applications'),
        applicationInfo
      );
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };
};

export const useApplication = (id: string) => {
  return useQuery(['applications', id], async () => {
    const res = await fetch(`${HOSTNAME}/api/applications/${id}`);
    const { data } = await res.json();
    return data;
  });
};
