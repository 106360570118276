import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { AppHeader } from './AppHeader';
import { Login } from './pages/Login/login';
import { Quotes } from './pages/Quotes/quotes';
import { AuthRequired } from './pages/Login/AuthProvider';
import { Applications } from './pages/Applications/applications';
import { Signup } from './pages/Login/signup';
import { BecomeAnLO, ScheduleADemo } from './pages/Login/scheduleDemo';
import { Contacts } from './pages/Contacts/contacts';
// import Bugsnag from '@bugsnag/js';

const queryClient = new QueryClient();
console.log('jaxon test');
const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Routes>
          <Route
            path="/client"
            element={
              <AuthRequired>
                <>
                  <AppHeader />
                  <Outlet />
                </>
              </AuthRequired>
            }
          >
            <Route path="quotes" element={<Quotes />} />
            <Route path="applications" element={<Applications />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="*" element={<Navigate to="/client/quotes" />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/schedule-demo" element={<ScheduleADemo />} />
          <Route path="/get-licensed" element={<BecomeAnLO />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </div>
    </QueryClientProvider>
  );
};

export default App;
