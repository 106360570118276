import React, { useState } from 'react';
import { useAuth } from '../Login/AuthProvider';
import { useAddApplication } from './useApplications';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { RJSFSchema } from '@rjsf/utils';
import { Button, Grid } from '@mui/material';

const schema: RJSFSchema = {
  title: 'Application Form',
  type: 'object',
  required: [
    'creditScore',
    'zipCode',
    'loanPurpose',
    'purchasePrice',
    'ltv',
    'firstTimeHomebuyer',
    'isMilitary',
    'lockPeriod',
    'loanTerm',
    'occupancyType',
    'propertyType',
    'numUnits',
    'compensationType',
    'clientName',
    'email',
    'phone',
  ],
  properties: {
    creditScore: {
      type: 'string',
      title: 'Estimated Credit Score',
      oneOf: [
        { const: '780+', title: '780+' },
        { const: '720-779', title: '720-779' },
        { const: '680-719', title: '680-719' },
        { const: '680-', title: 'Less than 680' },
      ],
    },
    zipCode: { type: 'string', title: 'Zip Code' },
    loanPurpose: {
      type: 'string',
      title: 'Loan Purpose',
      oneOf: [
        { const: 'Purchase', title: 'Purchase' },
        { const: 'Refinance - Rate/Term', title: 'Refinance - Rate/Term' },
        { const: 'Refinance - Cash Out', title: 'Refinance - Cash Out' },
      ],
    },
    purchasePrice: {
      type: 'number',
      title: 'Purchase Price or Estimated Value',
    },
    ltv: {
      type: 'number',
      title: 'LTV',
    },
    firstTimeHomebuyer: {
      type: 'boolean',
      title: 'Is Anyone on the Loan a First-time Buyer?',
      default: false,
    },
    isMilitary: {
      type: 'boolean',
      title: 'Is Anyone on the loan Active military or Veteran?',
      default: false,
    },
    lockPeriod: {
      type: 'string',
      title: 'Lock Period?',
      oneOf: [
        { const: '30', title: '30' },
        { const: '60', title: '60' },
        { const: '90', title: '90' },
        { const: '120', title: '120' },
        { const: '150', title: '150' },
        { const: '180', title: '180' },
      ],
    },
    loanTerm: {
      type: 'string',
      title: 'Loan Term',
      oneOf: [
        { const: '30', title: '30' },
        { const: '15', title: '15' },
        { const: '10', title: '10' },
      ],
    },
    occupancyType: {
      type: 'string',
      title: 'Occupancy Type',
      oneOf: [
        { const: 'primaryHome', title: 'Primary Residence' },
        { const: 'secondHome', title: '2nd Home' },
        { const: 'investmentHome', title: 'Investment Property' },
      ],
    },
    propertyType: {
      type: 'string',
      title: 'Property Type',
      oneOf: [
        { const: 'sfr', title: 'SFR' },
        { const: 'condo', title: 'Condo' },
        { const: 'townhome', title: 'Townhome' },
        { const: 'manufactured', title: 'Manufactured' },
        { const: 'multiUnit', title: 'Multi-unit' },
      ],
    },
    numUnits: {
      type: 'string',
      title: 'Number of Units',
      oneOf: [
        { const: '1', title: '1' },
        { const: '2', title: '2' },
        { const: '3', title: '3' },
        { const: '4', title: '4' },
      ],
    },
    compensationType: {
      type: 'string',
      title: 'Compensation Type',
      oneOf: [
        { const: 'borrowerPaid', title: 'Borrower Paid' },
        { const: 'lenderPaid', title: 'Lender Paid' },
      ],
    },
    clientName: {
      type: 'string',
      title: 'Name',
    },
    email: {
      type: 'string',
      title: 'Email',
    },
    phone: {
      type: 'string',
      title: 'Phone',
    },
  },
};

const log = (type) => console.log.bind(console, type);

interface FormProps {
  onSubmit?: (form: any) => void;
}
export const ApplicationForm: React.FC<FormProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const auth = useAuth();

  const addApplication = useAddApplication();
  const handleAddApplication = async (formData) => {
    setIsLoading(true);
    await addApplication({
      ...formData,
      userId: auth.user?.uid || '',
    });
    setIsLoading(false);
    props.onSubmit && props.onSubmit(formData);
  };

  const handleSubmitApplication = (form) => {
    console.log(form.formData);
    log('submitted');
    handleAddApplication(form.formData);
  };
  return (
    <>
      <Form
        schema={schema}
        validator={validator}
        onChange={log('changed')}
        onSubmit={handleSubmitApplication}
        onError={log('errors')}
      >
        <Grid container>
          <Grid item xs={12}>
            <Button
              type="submit"
              disabled={isLoading}
              variant="contained"
              fullWidth
              size="large"
            >
              Submit Application
            </Button>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};
