import React from 'react';
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { SignupPage } from './signupPage';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import { Form } from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import StarIcon from '@mui/icons-material/Star';

const infoSchema: RJSFSchema = {
  type: 'object',
  required: ['firstName', 'lastName'],
  properties: {
    firstName: { type: 'string', title: 'First Name' },
    lastName: { type: 'string', title: 'Last Name' },
    address: { type: 'string', title: 'Address' },
    phone: { type: 'string', title: 'Phone' },
  },
};
const nmlsSchema: RJSFSchema = {
  type: 'object',
  required: ['nmlsNum'],
  properties: {
    nmlsNum: { type: 'string', title: 'NMLS Number' },
  },
};
const uiSchema: UiSchema = {
  'ui:submitButtonOptions': {
    submitText: 'Next',
    norender: true,
    props: {
      disabled: false,
      className: 'btn btn-info',
    },
  },
};
const WizardCard: React.FC<{
  title: string;
  subheader?: string;
  children: React.ReactNode;
}> = (props) => {
  return (
    <Grid item sx={{ flex: '1' }}>
      <Grid container direction="column" sx={{ height: '100%' }}>
        <h1>{props.title}</h1>
        {props.subheader && <p>{props.subheader}</p>}
        {props.children}
      </Grid>
    </Grid>
  );
};
const paymentPlans = [
  { label: 'Yearly', value: 'yearly', price: 600 },
  { label: 'Monthly', value: 'monthly', price: 70 },
  { label: 'Weekly', value: 'weekly', price: 25 },
];
const SignupWizard: React.FC<{ activeStep: number; onComplete: () => void }> = (
  props
) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [paymentPlan, setPaymentPlan] = React.useState('yearly');
  const progress = React.useMemo(() => {
    return (activeStep / 4) * 100;
  }, [activeStep]);

  return (
    <Grid container direction={'column'} sx={{ height: '100vh' }}>
      {activeStep !== 0 && (
        <Grid item>
          <WizardProgress progress={progress} />
        </Grid>
      )}
      {activeStep === 0 && (
        <SignupPage
          onSignup={() => {
            setActiveStep(activeStep + 1);
          }}
        />
      )}
      {activeStep === 1 && (
        <WizardCard title="Your Info">
          <Grid item sx={{ flex: '1' }}>
            <Form
              schema={infoSchema}
              uiSchema={uiSchema}
              validator={validator}
            />
          </Grid>
          <Grid item sx={{ margin: '12px 0' }}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => setActiveStep(activeStep + 1)}
            >
              Next
            </Button>
          </Grid>
        </WizardCard>
      )}
      {activeStep === 2 && (
        <WizardCard title="Loan Officer Credentials">
          <Grid item sx={{ flex: '1' }}>
            <Form
              schema={nmlsSchema}
              uiSchema={uiSchema}
              validator={validator}
              onSubmit={() => setActiveStep(activeStep + 1)}
            />
          </Grid>
          <Grid item sx={{ margin: '12px 0' }}>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              style={{ marginBottom: '12px' }}
              href="/get-licensed"
            >
              I need to get licensed
            </Button>
            <Button
              type="button"
              size="large"
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => setActiveStep(activeStep + 1)}
            >
              Next
            </Button>
          </Grid>
        </WizardCard>
      )}
      {activeStep === 3 && (
        <WizardCard
          title="Choose your subscription plan"
          subheader="And get a 7-day free trial"
        >
          <Grid item sx={{ flex: '1' }}>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={paymentPlan}
              onChange={(e) => setPaymentPlan(e.target.value)}
            >
              {paymentPlans.map((plan) => (
                <Box
                  sx={{
                    border: '1px solid lightgray',
                    borderRadius: '12px',
                    padding: '12px',
                    marginBottom: '12px',
                    backgroundColor:
                      plan.value === paymentPlan ? '#EAF2FF' : 'white',
                  }}
                >
                  <FormControlLabel
                    key={plan.label}
                    value={plan.value}
                    control={<Radio />}
                    style={{ width: '100%' }}
                    label={`${plan.label} - $${plan.price}`}
                  />
                </Box>
              ))}
            </RadioGroup>
            <Box
              sx={{
                background: '#EAF2FF',
                borderRadius: '12px',
                padding: '12px',
              }}
            >
              <h2>You'll get:</h2>
              <Grid container direction="row" alignItems="center">
                <StarIcon color="primary" />
                <p>Unlimited Support from our specialists</p>
              </Grid>
              <Grid container direction="row" alignItems="center">
                <StarIcon color="primary" />
                <p>Access to our network of Lenders and Processors</p>
              </Grid>
              <Grid container direction="row" alignItems="center">
                <StarIcon color="primary" />
                <p>Sync all your devices</p>
              </Grid>
            </Box>
          </Grid>
          <Grid item sx={{ margin: '12px 0' }}>
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => setActiveStep(activeStep + 1)}
            >
              Subscribe
            </Button>
          </Grid>
        </WizardCard>
      )}
      {activeStep === 4 && (
        <WizardCard title="Payment" subheader="Choose a payment method">
          <Grid item sx={{ flex: '1' }}>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value="creditCard"
            >
              <Box
                sx={{
                  border: '1px solid lightgray',
                  borderRadius: '12px',
                  padding: '12px',
                  marginBottom: '12px',
                  backgroundColor: '#EAF2FF',
                }}
              >
                <FormControlLabel
                  value={'creditCard'}
                  control={<Radio />}
                  style={{ width: '100%' }}
                  label="Credit Card"
                />
              </Box>
            </RadioGroup>
          </Grid>
          <Grid item sx={{ margin: '12px 0' }}>
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                props.onComplete();
              }}
            >
              Confirm Payment
            </Button>
          </Grid>
        </WizardCard>
      )}
    </Grid>
  );
};
const WizardProgress = (props: { progress: number }) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ width: '100%', height: '48px' }}
    >
      <Box sx={{ width: '100%' }}>
        <LinearProgress
          variant="determinate"
          value={props.progress}
          style={{ height: '8px', borderRadius: '6px' }}
        />
      </Box>
    </Grid>
  );
};

export const Signup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // @ts-ignore
  const from = location.state?.from?.pathname || '/client/quotes';
  return (
    <Grid container sx={{ height: '100vh' }}>
      <Grid item xs={12} md={6}>
        <Container>
          <SignupWizard
            activeStep={0}
            onComplete={() => {
              navigate(from, { replace: true });
            }}
          />
        </Container>
      </Grid>
    </Grid>
  );
};
