import React from 'react';
import ReactDOM from 'react-dom/client';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.scss';

import {
  FirebaseAppProvider,
  DatabaseProvider,
  AnalyticsProvider,
  AuthProvider as FirebaseAuthProvider,
  useFirebaseApp,
  useAnalytics,
  AppCheckProvider,
  useInitFirestore,
  FirestoreProvider,
} from 'reactfire';

// Import the functions you need from the SDKs you need
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getAuth } from 'firebase/auth'; // Firebase v9+
import { getDatabase } from 'firebase/database'; // Firebase v9+
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import {
  initializeFirestore,
  enableIndexedDbPersistence,
} from 'firebase/firestore';
import App from './App';
import { AuthProvider } from './pages/Login/AuthProvider';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';
import { ThemeProvider, createTheme } from '@mui/material';

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#714afc',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
});

let firebaseConfig = {
  apiKey: 'AIzaSyCdb9MGqoyDmxwrzzpMncinLx-wr481dX4',
  authDomain: 'doubleagent-dev.firebaseapp.com',
  projectId: 'doubleagent-dev',
  storageBucket: 'doubleagent-dev.appspot.com',
  messagingSenderId: '407690090612',
  appId: '1:407690090612:web:873dda4340f280be4c9921',
  measurementId: 'G-M2333KTDQ6',
};

const hostNames = {
  production: 'https://us-central1-doubleagentpro.cloudfunctions.net',
  development: 'https://us-central1-doubleagent-dev.cloudfunctions.net',
  local: 'http://127.0.0.1:5001/doubleagent-dev/us-central1',
};

export const HOSTNAME =
  hostNames[process.env.REACT_APP_NODE_ENV || 'development'];
console.log('Jaxon env: ', process.env);
if (process.env.REACT_APP_NODE_ENV === 'production') {
  console.log('jaxon config for prodA: ', firebaseConfig);

  firebaseConfig = {
    apiKey: 'AIzaSyC2iHgt-d1Tf926RdodcHwGKnhFeDtkbeQ',
    authDomain: 'doubleagentpro.firebaseapp.com',
    projectId: 'doubleagentpro',
    storageBucket: 'doubleagentpro.appspot.com',
    messagingSenderId: '744200190513',
    appId: '1:744200190513:web:d63179132494849e7bf310',
    measurementId: 'G-XE5HBX2LMN',
  };
  console.log('jaxon config for prodB: ', firebaseConfig);
}

const MyPageViewLogger = ({ location }) => {
  const analytics = useAnalytics();

  // By passing `location.pathname` to the second argument of `useEffect`,
  // we only log on first render and when the `pathname` changes
  React.useEffect(() => {
    logEvent(analytics, 'page_view', { page_location: location.href });
  }, [location.href]);

  return null;
};

const FirebaseComponents = (props) => {
  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
  const { status, data: firestoreInstance } = useInitFirestore(
    async (firebaseApp) => {
      const db = initializeFirestore(firebaseApp, {});
      // await enableIndexedDbPersistence(db);
      return db;
    }
  );
  // Initialize Firebase
  // const appCheck = initializeAppCheck(app, {
  //   provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
  //   isTokenAutoRefreshEnabled: true,
  // });
  // initialize Database and Auth with the normal Firebase SDK functions
  const database = getDatabase(app);
  const auth = getAuth(app);
  const analytics = getAnalytics(app);

  if (status === 'loading') {
    return <div>Loading databases...</div>;
  }
  // any child components will be able to use `useUser`, `useDatabaseObjectData`, etc
  return (
    // <AppCheckProvide sdk={appCheck}r>
    <ThemeProvider theme={theme}>
      <AnalyticsProvider sdk={analytics}>
        <FirestoreProvider sdk={firestoreInstance}>
          <FirebaseAuthProvider sdk={auth}>
            <DatabaseProvider sdk={database}>{props.children}</DatabaseProvider>
          </FirebaseAuthProvider>
        </FirestoreProvider>
      </AnalyticsProvider>
    </ThemeProvider>
    // </AppCheckProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <FirebaseComponents>
      <React.StrictMode>
        <Router>
          <AuthProvider>
            {/*   <PaywallProvider> */}
            <App />
            {/* </PaywallProvider>*/}
          </AuthProvider>
          <MyPageViewLogger location={window.location} />
        </Router>
      </React.StrictMode>
    </FirebaseComponents>
  </FirebaseAppProvider>
);
