import React, { useState } from 'react';
import { useAuth } from '../Login/AuthProvider';
import { useAddContact } from './useContacts';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { RJSFSchema } from '@rjsf/utils';
import { Button, Grid } from '@mui/material';

const schema: RJSFSchema = {
  title: 'Add Contact',
  type: 'object',
  required: ['firstName', 'lastName', 'email'],
  properties: {
    firstName: {
      type: 'string',
      title: 'First Name',
    },
    lastName: { type: 'string', title: 'Last Name' },

    email: {
      type: 'string',
      title: 'Email',
      format: 'email',
    },
    phone: {
      type: 'string',
      title: 'Phone',
    },
  },
};

const log = (type) => console.log.bind(console, type);

interface FormProps {
  onSubmit?: (form: any) => void;
}
export const NewContactForm: React.FC<FormProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const auth = useAuth();

  const addContact = useAddContact();
  const handleAddContact = async (formData) => {
    setIsLoading(true);
    await addContact({
      ...formData,
      userId: auth.user?.uid || '',
    });
    setIsLoading(false);
    props.onSubmit && props.onSubmit(formData);
  };

  const handleSubmitApplication = (form) => {
    console.log(form.formData);
    log('submitted');
    handleAddContact(form.formData);
  };
  return (
    <>
      <Form
        schema={schema}
        validator={validator}
        onChange={log('changed')}
        onSubmit={handleSubmitApplication}
        onError={log('errors')}
      >
        <Grid container>
          <Grid item xs={12}>
            <Button
              type="submit"
              disabled={isLoading}
              variant="contained"
              fullWidth
              size="large"
            >
              Add Contact
            </Button>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};
