import { useQuery } from 'react-query';
import { collection, addDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { HOSTNAME } from '../../index';

export const useContacts = () => {
  return useQuery('contacts', async () => {
    const res = await fetch(`${HOSTNAME}/api/contacts`);
    const { data } = await res.json();
    return data;
  });
};

export const useAddContact = () => {
  const firestore = useFirestore();
  return async (contactInfo: {
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
    userId: string;
  }) => {
    debugger;

    try {
      const docRef = await addDoc(
        collection(firestore, 'contacts'),
        contactInfo
      );
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };
};

export const useContact = (id: string) => {
  return useQuery(['contacts', id], async () => {
    const res = await fetch(`${HOSTNAME}/api/contacts/${id}`);
    const { data } = await res.json();
    return data;
  });
};
